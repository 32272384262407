import React from 'react'

export const Blob1 = ({left, right, top, color}) => (
    <div className={`z-0 blob blob-${color} absolute h-full}`} style={{ left: left, right: right, top: top}}>
        <svg viewBox="0 0 347 360" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M198.723 7.78021C233.455 16.2493 259.569 42.2071 284.074 68.1899C304.476 89.8217 316.586 116.007 327.032 143.825C338.01 173.059 346.905 201.994 346.037 233.202C344.869 275.211 356.602 330.918 321.197 353.667C285.234 376.774 241.47 329.322 198.723 327.855C158.486 326.473 119.527 360.579 82.224 345.461C42.6953 329.44 14.7952 289.797 3.50419 248.736C-7.54405 208.559 9.95211 168.024 22.5052 128.289C35.1826 88.1602 41.752 41.3653 76.5303 17.5818C111.084 -6.04809 158.033 -2.14187 198.723 7.78021Z" fill="currentColor"/>
        </svg>
    </div>
)

export const Blob2 = ({left, right, top, color}) => (
    <div className={`z-0 blob blob-${color} absolute h-full`} style={{ left: left, right: right, top: top}}>
        <svg viewBox="0 0 536 513" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M257.513 6.8963C306.669 13.595 350.494 36.7662 389.448 67.4698C427.033 97.0946 452.424 136.149 475.01 178.328C502.671 229.983 543.34 281.046 534.859 339.016C525.922 400.105 482.377 453.493 429.971 486.182C379.422 517.712 317.084 513.364 257.513 512.014C199.37 510.696 138.326 511.773 90.6467 478.49C42.5283 444.901 5.53769 390.9 0.315122 332.474C-4.54182 278.138 48.1057 238.312 64.5276 186.287C81.1834 133.521 56.0249 65.2398 96.563 27.5573C137.164 -10.1832 202.574 -0.590481 257.513 6.8963Z" fill="currentColor"/>
        </svg>
    </div>
)

export const Blob3 = ({left, right, top, color}) => (
    <div className={`z-0 blob blob-${color} absolute h-full`} style={{ left: left, right: right, top: top}}>
        <svg viewBox="0 0 676 643" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M327.255 3.64775C400.946 -13.2148 484.939 30.5262 530.62 90.7582C573.631 147.469 526.26 231.21 550.577 298.104C576.487 369.38 692.155 409.64 673.236 483.082C654.734 554.905 545.722 547.641 478.321 578.591C427.453 601.949 383.201 640.154 327.255 641.952C270.201 643.786 218.359 617.149 168.965 588.534C113.985 556.683 54.3546 525.611 27.2401 468.147C-1.60949 407.006 -12.2869 330.46 18.8851 270.471C49.0042 212.507 132.474 213.304 181.871 170.562C239.067 121.072 253.526 20.5191 327.255 3.64775Z" fill="currentColor"/>
        </svg>
    </div>
)

export const BlobCenter = ({color}) => (
    <div className={`z-0 w-full blob-${color} max-w-xl p-8 absolute absolute-center`}>
        <svg viewBox="0 0 685 404" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.7726 226.814C-9.14909 177.63 6.28816 118.128 65.6274 85.8415C124.2 53.9724 216.051 87.1146 289.13 72.0969C361.563 57.2121 406.128 -5.15392 481.686 0.341898C556.907 5.81318 605.972 55.4703 643.238 97.8535C677.55 136.877 693.138 182.688 680.814 226.814C669.667 266.723 624.118 297.339 579.518 325.995C541.218 350.604 494.499 366.172 444.111 378.901C390.316 392.49 335.228 410.027 278.811 401.958C219.848 393.524 178.958 362.232 136.11 334.847C84.4307 301.818 17.8223 272.916 4.7726 226.814Z" fill="currentColor"/>
        </svg>
    </div>
)

export const BlobMask = ({imgSrc}) => (
    <div className="md:p-8 relative w-full h-full p-8 pt-0">
        <svg width="100%" height="100%" viewBox="0 0 407 443" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="blogMask" clipPathUnits="userSpaceOnUse">
                    <path fillRule="evenodd" clipRule="evenodd" d="M165.308 0.796483C212.709 6.88506 231.321 65.8451 270.989 92.5341C313.164 120.911 384.612 113.432 403.136 160.813C421.464 207.691 369.613 251.811 351.644 298.829C334.955 342.498 339.751 399.094 301.712 426.209C263.71 453.297 210.828 441.849 165.308 431.65C127.273 423.128 96.4601 399.245 67.0498 373.632C40.1739 350.227 13.7179 325.014 3.59577 290.817C-6.37212 257.14 6.9963 223.118 12.2181 188.385C18.7403 145.002 10.1841 96.5648 37.9069 62.5948C68.5162 25.0878 117.331 -5.36622 165.308 0.796483Z" fill="#C4C4C4"/>
                </clipPath>
            </defs>
            <image width="100%" height="100%" preserveAspectRatio="xMinYMin slice" clipPath="url(#blogMask)" xlinkHref={imgSrc} />
        </svg>
    </div>
)

export const BlobMask2 = ({imgSrc}) => (
    <div className="md:p-8 relative w-full h-full p-8 pt-0">
        <svg width="100%" height="100%" viewBox="0 0 407 367" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="blogMask2" clipPathUnits="userSpaceOnUse">
                    <path fillRule="evenodd" clipRule="evenodd" d="M194.099 0.541583C226.09 2.80124 253.098 20.6159 282.705 33.9194C323.721 52.3494 380.292 50.5236 400.527 93.2971C420.523 135.566 389.073 184.477 375.247 229.542C361.524 274.27 357.382 329.01 320.512 354.105C283.949 378.991 236.499 361.768 194.099 352.837C159.242 345.495 129.342 327.441 99.0167 307.451C65.6316 285.443 24.7694 269.647 9.11249 230.891C-7.03715 190.916 0.0379566 143.243 16.6114 103.471C32.1556 66.1686 63.3351 40.1097 96.6686 20.7788C126.546 3.45199 160.271 -1.8478 194.099 0.541583Z" fill="#C4C4C4"/>
                </clipPath>
            </defs>
            <image width="100%" height="100%" preserveAspectRatio="xMinYMin slice" clipPath="url(#blogMask2)" xlinkHref={imgSrc} />
        </svg>
    </div>
)
