import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax';
import Header from './header'
import Footer from './footer'
import '../styles/all.sass'

const Layout = ({ children }) => {
  return (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ParallaxProvider>
        <div className="dark-mode:bg-deep text-deep dark-mode:text-neige overflow-x-hidden font-sans bg-white">
          <Header siteTitle={data.site.siteMetadata.title} />
          <div>
            {children}
          </div>
          <Footer />
        </div>
      </ParallaxProvider>
    )}
  />
)}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
