import React from 'react'
import { motion } from 'framer-motion'
import TransitionLink from 'gatsby-plugin-transition-link'

const wavyVariants = {
  hover: { x: 20 },
}

export const WavyLink = ({ slug, text, external }) => {
  return external ? (
    <a target="_blank" rel="noopener noreferrer" href={slug}>
      <motion.div whileHover="hover" className="w-full text-3xl font-serif">
        <svg
          width="97"
          height="23"
          viewBox="0 0 97 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            variants={wavyVariants}
            d="M-27 8C-27 8 -19.5453 16 -13.375 16C-7.20471 16 -5.92029 8 0.25 8C6.42029 8 7.70471 16 13.875 16C20.0453 16 21.3297 8 27.5 8C33.6703 8 34.9547 16 41.125 16C47.2953 16 48.5797 8 54.75 8C60.9203 8 62.2047 16 68.375 16C74.5453 16 75.8297 8 82 8C88.1703 8 89.4547 16 95.625 16C101.795 16 103.08 8 109.25 8C115.42 8 116.705 16 122.875 16C129.045 16 130.33 8 136.5 8C142.67 8 143.955 16 150.125 16C156.295 16 157.58 8 163.75 8C169.92 8 171.205 16 177.375 16C183.545 16 191 8 191 8"
            stroke="currentColor"
            strokeWidth="2"
          />
        </svg>
        <span className="italic">{text}</span>
      </motion.div>
    </a>
  ) : (
    <TransitionLink to={`/${slug}`}>
      <motion.div whileHover="hover" className="w-full text-3xl font-serif">
        <svg
          width="97"
          height="23"
          viewBox="0 0 97 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            variants={wavyVariants}
            d="M-27 8C-27 8 -19.5453 16 -13.375 16C-7.20471 16 -5.92029 8 0.25 8C6.42029 8 7.70471 16 13.875 16C20.0453 16 21.3297 8 27.5 8C33.6703 8 34.9547 16 41.125 16C47.2953 16 48.5797 8 54.75 8C60.9203 8 62.2047 16 68.375 16C74.5453 16 75.8297 8 82 8C88.1703 8 89.4547 16 95.625 16C101.795 16 103.08 8 109.25 8C115.42 8 116.705 16 122.875 16C129.045 16 130.33 8 136.5 8C142.67 8 143.955 16 150.125 16C156.295 16 157.58 8 163.75 8C169.92 8 171.205 16 177.375 16C183.545 16 191 8 191 8"
            stroke="currentColor"
            strokeWidth="2"
          />
        </svg>
        <span className="italic">{text}</span>
      </motion.div>
    </TransitionLink>
  )
}
