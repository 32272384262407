import React from 'react'
import { BlobCenter } from '../components/blobs'
import { WavyLink } from '../components/wavyLink'

const Footer = () => (
  <div className="text-bleu dark-mode:text-neige md:p-20 relative flex flex-row p-10 text-center">
      <BlobCenter color="stone"/>
      <div className="md:p-20 relative z-10 flex flex-col items-center justify-between w-full h-full p-10">
        <p className="dark-mode:text-neige mb-5">Wanna get started on a project?</p>
        <WavyLink slug="mailto:skarges3@gmail.com" text="get in touch" external />
      </div>
      <div className="py-10" />
  </div>
)

export default Footer
