import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Blob1 } from './blobs'

const Header = ({ siteTitle }) => (
  <div className="text-bleu dark-mode:text-neige md:p-12 relative z-10 flex flex-row items-center justify-between p-8 overflow-visible font-mono">
      <div className="absolute top-0 left-0 w-full h-full overflow-visible">
        <Blob1 left="-80px" top="-80px" color="neige"/>
      </div>
      <Link to={`/`} className="text-bleu dark-mode:text-stone relative block w-48" aria-label="Homepage: eskargeaux">
        <svg  viewBox="0 0 223 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.8125 51.8519C10.6875 51.8519 0 41.1093 0 27.9166C0 11.4729 11.1094 0 27.0234 0C36.5156 0 45.0469 3.81645 51.0703 10.7661C56.8359 17.4096 60 26.4795 60 36.3269H55.7812C55.7812 27.5161 52.9688 19.4356 47.8828 13.5696C42.6094 7.46799 35.3906 4.2405 27.0234 4.2405C13.3828 4.2405 4.21875 13.7581 4.21875 27.9166C4.21875 38.777 13.0078 47.6114 23.8125 47.6114C32.2266 47.6114 39.0703 40.7323 39.0703 32.2749C39.0703 25.7492 33.7734 20.4251 27.2813 20.4251C22.3359 20.4251 18.2812 24.5006 18.2812 29.4715C18.2812 33.2408 21.3281 36.2798 25.0547 36.2798C27.8203 36.2798 30.0703 34.0182 30.0703 31.2383C30.0703 28.5527 28.9687 28.3407 27.6562 28.3407C25.9688 28.3407 25.9219 30.7672 25.9219 31.0263H21.7031C21.7031 26.9978 24.2109 24.1002 27.6562 24.1002C31.8047 24.1002 34.2891 26.7622 34.2891 31.2383C34.2891 36.3505 30.1406 40.5203 25.0547 40.5203C19.0078 40.5439 14.0625 35.5731 14.0625 29.4715C14.0625 22.1448 19.9922 16.1846 27.2813 16.1846C36.1172 16.1846 43.2891 23.3934 43.2891 32.2749C43.2891 43.0646 34.5703 51.8519 23.8125 51.8519Z" fill="currentColor"/>
          <path d="M79.9295 26.44C80.0975 24.788 80.6575 24.34 81.3295 24.34C82.0575 24.34 82.4775 24.872 82.4775 25.712C82.4775 26.132 82.4495 26.44 82.4495 26.44H79.9295ZM81.4695 34.336C84.0175 34.336 85.6975 33.748 86.8175 32.908V29.8C85.9215 30.444 84.4095 30.948 83.0655 30.948C81.0215 30.948 80.0415 30.164 79.9015 28.54H86.8455C86.9575 28.148 87.0415 27.252 87.0415 26.524C87.0415 23.444 84.4095 21.568 81.2455 21.568C77.8575 21.568 74.6935 24.004 74.6935 27.868C74.6935 31.62 77.4375 34.336 81.4695 34.336Z" fill="currentColor"/>
          <path d="M94.0408 34.392C97.8768 34.392 99.6968 32.572 99.6968 29.884C99.6968 28.232 98.5768 26.524 94.5448 25.852C93.9008 25.74 93.5368 25.572 93.5368 25.18C93.5368 24.872 93.8448 24.676 94.5168 24.676C95.6648 24.676 96.8688 24.984 98.3248 25.74L99.0528 22.772C97.8768 22.184 96.1968 21.54 94.2368 21.54C90.4008 21.54 88.8608 23.64 88.8608 25.572C88.8608 27.504 89.8408 29.24 93.5648 29.856C94.5448 30.024 95.0208 30.08 95.0208 30.612C95.0208 31.004 94.6568 31.256 93.8168 31.256C91.8848 31.256 90.5408 30.5 89.2808 29.744L88.5808 32.88C90.0088 33.72 91.7728 34.392 94.0408 34.392Z" fill="currentColor"/>
          <path d="M101.479 34H106.855V27.784L110.691 34H116.179L111.727 27.28L115.955 21.904H110.719L106.855 27.056V17.2H101.479V34Z" fill="currentColor"/>
          <path d="M122.018 34.336C123.138 34.336 124.986 33.944 126.022 32.516L126.134 34H131.202V21.904H126.134L126.022 23.388C124.986 21.96 123.138 21.568 122.018 21.568C118.798 21.568 116.474 24.144 116.474 27.952C116.474 31.816 118.798 34.336 122.018 34.336ZM123.894 30.612C122.718 30.612 121.878 29.66 121.878 27.952C121.878 26.244 122.718 25.292 123.894 25.292C125.07 25.292 125.938 26.244 125.938 27.952C125.938 29.66 125.07 30.612 123.894 30.612Z" fill="currentColor"/>
          <path d="M133.881 34H139.285V28.512C139.285 26.412 141.217 25.628 143.261 26.44V21.568C141.217 21.568 139.705 22.66 139.145 23.948L139.005 21.904H133.881V34Z" fill="currentColor"/>
          <path d="M158.901 32.6V21.904H153.833L153.721 23.388C152.685 21.96 150.837 21.568 149.717 21.568C146.497 21.568 144.173 24.088 144.173 27.952C144.173 31.76 146.497 34.336 149.717 34.336C150.837 34.336 152.405 34.028 153.497 32.796V33.076C153.497 35.148 152.405 36.184 150.529 36.184C148.793 36.184 147.029 35.764 145.265 34.644V38.2C146.721 39.04 149.269 39.712 151.173 39.712C154.589 39.712 158.901 38.592 158.901 32.6ZM151.593 30.612C150.417 30.612 149.577 29.66 149.577 27.952C149.577 26.244 150.417 25.292 151.593 25.292C152.769 25.292 153.637 26.244 153.637 27.952C153.637 29.66 152.769 30.612 151.593 30.612Z" fill="currentColor"/>
          <path d="M166.144 26.44C166.312 24.788 166.872 24.34 167.544 24.34C168.272 24.34 168.692 24.872 168.692 25.712C168.692 26.132 168.664 26.44 168.664 26.44H166.144ZM167.684 34.336C170.232 34.336 171.912 33.748 173.032 32.908V29.8C172.136 30.444 170.624 30.948 169.28 30.948C167.236 30.948 166.256 30.164 166.116 28.54H173.06C173.172 28.148 173.256 27.252 173.256 26.524C173.256 23.444 170.624 21.568 167.46 21.568C164.072 21.568 160.908 24.004 160.908 27.868C160.908 31.62 163.652 34.336 167.684 34.336Z" fill="currentColor"/>
          <path d="M180.452 34.336C181.572 34.336 183.42 33.944 184.456 32.516L184.568 34H189.636V21.904H184.568L184.456 23.388C183.42 21.96 181.572 21.568 180.452 21.568C177.232 21.568 174.908 24.144 174.908 27.952C174.908 31.816 177.232 34.336 180.452 34.336ZM182.328 30.612C181.152 30.612 180.312 29.66 180.312 27.952C180.312 26.244 181.152 25.292 182.328 25.292C183.504 25.292 184.372 26.244 184.372 27.952C184.372 29.66 183.504 30.612 182.328 30.612Z" fill="currentColor"/>
          <path d="M206.119 21.904H200.715V27.924C200.715 29.072 200.155 30.22 198.979 30.22C197.971 30.22 197.663 29.632 197.663 28.036V21.904H192.259V29.1C192.259 32.096 193.575 34.336 196.795 34.336C198.223 34.336 199.987 33.636 200.855 31.956L200.995 34H206.119V21.904Z" fill="currentColor"/>
          <path d="M207.123 34H212.583L214.683 31.032L216.755 34H222.327L217.623 27.756L222.047 21.904H216.727L214.767 24.648L212.863 21.904H207.403L211.799 27.868L207.123 34Z" fill="currentColor"/>
        </svg>
      </Link>
      <div className="md:not-sr-only relative font-sans text-2xl font-black sr-only">
        Spencer Karges <span className="font-serif italic font-normal">&mdash;</span> Developer
      </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
